import LayoutAdmin from "@/layouts/LayoutAdmin";
import myTable from "./table";

let urutan = 0;

export default {
  data() {
    return {
      kata_kunci: null,
      pilih_cif: null,
      search: "",
      loadingTb: false,
      pilihBalai: null,
      pilihProject: null,
      pilihSatker: null,
      isActive: [],
      listBalai: [],
      listProject: [],
      listStatker: [],
      listDataPaket: [],
      listGiat: [],
      listEvent: [],
      listVersi: [],
      listRegister: [],
      listKategori: [],
      listKodeAkun: [],
      listBeban: [],
      tbData: [],
      pgMin: 0,
      pgMax: 0,
      noUrut: 1,
      columnTab: [
        { text: "Kode", value: "c_1" },
        { text: "Kegiatan/Output/Paket/Sub Paket", value: "c_2" },
        { text: "Target Volume", value: "c_3" },
        { text: "Lokasi", value: "c_4" },
        { text: "Jenis Paket", value: "c_5" },
        { text: "Metode Pemilihan", value: "c_6" },
        { text: "Kode Proyek", value: "c_7" },
        { text: "Kode Register", value: "c_8" },
        { text: "Kode KPPN", value: "c_9" },
        { text: "Kode Beban", value: "c_10" },
        { text: "Pagu (Rp Ribu)", value: "c_11" },
        { text: "Realisasi (Rp Ribu)", value: "c_12" },
        { text: "% KEU", value: "c_13" },
        { text: "% FISIK", value: "c_14" },
      ],
      dataVersi: {
        saktiVersionId: "",
        filename: "",
        crtdt: "",
        tahun: 0,
        kdSatker: "",
        saktiVersionNm: "",
        asLatest: true,
        saktiEventId: 0,
        saktiVersionType: "",
        programs: [],
      },
      currentSubOutput: {
        id: "",
        beforeVol: 0,
        vol: 0,
        name: "",
      },
      currentSubKomponen: {
        id: "",
        urskmpnen: "",
      },
      currentItem: {
        listProgramCombo: [],
        listGiatCombo: [],
        listOutputCombo: [],
        listSOutputCombo: [],
        listKomponenCombo: [],
        listSKomponenCombo: [],
      },
      currentAkunEdit: {
        id: "",
        kdakun: "",
        register: "",
        kdbeban: "",
        kdkppn: "",
      },
      currentItemEdit: {
        nmitem: "",
      },
      paramVolkeg: 0,
      paramHargaSat: 0,
      paramJumlah: 0,
      currentKomentar: {
        key: 0,
        kdHistory: "",
        komentar: "",
        indexEdit: 0,
      },
      currentKomentarEdit: {
        key: 0,
        kdHistory: "",
        komentar: "",
        indexEdit: 0,
      },
      listKomentar: [],
    };
  },
  created() {
    this.$emit(`update:layout`, LayoutAdmin);
  },
  mounted() {
    this.G_GetRef("satker").then((data) => {
      this.listStatker = data;
      // this.listStatker.unshift({
      //   text: "Semua Satker",
      //   value: "all",
      // });
    });

    this.G_GetRef("akun").then((data) => {
      this.listKodeAkun = data.filter(function(item) {
        return ["51", "52", "53"].includes(item.value.substring(0, 2));
      });
      // this.listStatker.unshift({
      //   text: "Semua Satker",
      //   value: "all",
      // });
    });

    this.G_GetRef("register").then((data) => {
      this.listRegister = data;

      this.listRegister.forEach((v) => {
        v.text = v.value + " - " + v.text;
      });
    });

    this.G_GetRef("beban").then((data) => {
      console.log("beban");
      console.log(data);
      this.listBeban = data;
    });

    // this.G_GetAny("sakti/event").then((res) => {
    //   let resData = res.data.content;

    //   let listData = [];
    //   let arrSub = {};

    //   resData.forEach((v) => {
    //     arrSub = {};

    //     arrSub["text"] = v.saktiEventNm;
    //     arrSub["value"] = v.saktiEventId;

    //     listData.push(arrSub);
    //   });

    //   this.listEvent = listData;
    // });
  },
  components: {
    myTable,
  },
  methods: {
    getRegisterAkun: function(register) {
      if (register.trim()) {
        return "/ Reg. " + register;
      }

      return "";
    },
    getVersi() {
      this.$refs.versi.load(true);
      let filter = [];

      if (this.$refs.satker.getValue()) {
        if (this.$refs.satker.getValue() != "all") {
          filter.push("kdsatker=" + this.$refs.satker.getValue());
        }
      }

      // if (this.$refs.event.getValue()) {
      //   if (this.$refs.event.getValue() != "all") {
      //     filter.push("saktiEventId=" + this.$refs.event.getValue());
      //   }
      // }

      filter.push("saktiEventId=" + this.$route.query.eventId);

      if (filter.length > 0) {
        filter = "?" + filter.join("&");
      }

      console.log(filter);

      if (!this.$refs.satker.getValue()) {
        this.$refs.versi.load(false);
        return;
      }

      this.G_GetAny("rkakl/versi" + filter).then((res) => {
        let resData = res.data;

        let listData = [];
        let arrSub = {};

        resData.forEach((v) => {
          arrSub = {};

          arrSub["text"] = v.saktiVersionNm;
          arrSub["value"] = v.saktiVersionId;

          listData.push(arrSub);
        });

        this.listVersi = listData;
        this.$refs.versi.load(false);
      });
    },
    filterGiatByProgram() {
      this.$refs.giat.load(true);
      this.currentItem.listGiatCombo = [];

      if (this.$refs.program.getValue()) {
        var arrayProgram = this.$refs.program.getValue().split(".");
        console.log(arrayProgram);

        if (this.dataVersi.programs) {
          this.dataVersi.programs.forEach((v) => {
            if (v.kdprogram == arrayProgram[5]) {
              v.giats.forEach((b) => {
                this.currentItem.listGiatCombo.push({
                  text: b.kdgiat + " - " + b.nmgiat,
                  value:
                    b.thang +
                    "." +
                    b.kdjendok +
                    "." +
                    b.kdsatker +
                    "." +
                    b.kddept +
                    "." +
                    b.kdunit +
                    "." +
                    b.kdprogram +
                    "." +
                    b.kdgiat,
                });
              });
            }
          });
        }
      }

      console.log(this.currentItem.listGiatCombo);

      this.$refs.giat.load(false);
    },
    filterOutputByGiat() {
      this.$refs.output.load(true);
      this.currentItem.listOutputCombo = [];

      if (this.$refs.giat.getValue()) {
        var giats = this.$refs.giat.getValue().split(".");
        console.log(giats);

        if (this.dataVersi.programs) {
          this.dataVersi.programs.forEach((v) => {
            if (v.kdprogram == giats[5]) {
              v.giats.forEach((b) => {
                if (b.kdgiat == giats[6]) {
                  b.outputs.forEach((c) => {
                    this.currentItem.listOutputCombo.push({
                      text:
                        c.kdgiat + "." + c.kdoutput + " - " + c.output.nmoutput,
                      value:
                        c.thang +
                        "." +
                        c.kdjendok +
                        "." +
                        c.kdsatker +
                        "." +
                        c.kddept +
                        "." +
                        c.kdunit +
                        "." +
                        c.kdprogram +
                        "." +
                        c.kdgiat +
                        "." +
                        c.kdoutput,
                    });
                  });
                }
              });
            }
          });
        }
      }

      this.$refs.output.load(false);
    },
    filterSOutputByOutput() {
      this.$refs.soutput.load(true);
      this.currentItem.listSOutputCombo = [];

      if (this.$refs.output.getValue()) {
        var outputs = this.$refs.output.getValue().split(".");
        console.log(outputs);

        if (this.dataVersi.programs) {
          this.dataVersi.programs.forEach((v) => {
            if (v.kdprogram == outputs[5]) {
              v.giats.forEach((b) => {
                if (b.kdgiat == outputs[6]) {
                  b.outputs.forEach((c) => {
                    if (c.kdoutput == outputs[7]) {
                      c.saktiDSoutputs.forEach((d) => {
                        this.currentItem.listSOutputCombo.push({
                          text:
                            d.kdgiat +
                            "." +
                            d.kdoutput +
                            "." +
                            d.kdsoutput +
                            " - " +
                            d.ursoutput,
                          value:
                            d.thang +
                            "." +
                            d.kdjendok +
                            "." +
                            d.kdsatker +
                            "." +
                            d.kddept +
                            "." +
                            d.kdunit +
                            "." +
                            d.kdprogram +
                            "." +
                            d.kdgiat +
                            "." +
                            d.kdoutput +
                            "." +
                            d.kdsoutput,
                        });
                      });
                    }
                  });
                }
              });
            }
          });
        }
      }

      this.$refs.soutput.load(false);
    },
    filterKomponenBySOutput() {
      this.$refs.komponen.load(true);
      this.currentItem.listKomponenCombo = [];

      if (this.$refs.soutput.getValue()) {
        var soutputs = this.$refs.soutput.getValue().split(".");
        console.log(soutputs);

        if (this.dataVersi.programs) {
          this.dataVersi.programs.forEach((v) => {
            if (v.kdprogram == soutputs[5]) {
              v.giats.forEach((b) => {
                if (b.kdgiat == soutputs[6]) {
                  b.outputs.forEach((c) => {
                    if (c.kdoutput == soutputs[7]) {
                      c.saktiDSoutputs.forEach((d) => {
                        if (d.kdsoutput == soutputs[8]) {
                          d.saktiDKmpnens.forEach((e) => {
                            this.currentItem.listKomponenCombo.push({
                              text: e.kdkmpnen + " - " + e.urkmpnen,
                              value:
                                e.thang +
                                "." +
                                e.kdjendok +
                                "." +
                                e.kdsatker +
                                "." +
                                e.kddept +
                                "." +
                                e.kdunit +
                                "." +
                                e.kdprogram +
                                "." +
                                e.kdgiat +
                                "." +
                                e.kdoutput +
                                "." +
                                e.kdsoutput +
                                "." +
                                e.kdkmpnen,
                            });
                          });
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        }
      }

      this.$refs.komponen.load(false);
    },
    filterSubKomponenByKomponen() {
      this.$refs.skomponen.load(true);
      this.currentItem.listSKomponenCombo = [];

      if (this.$refs.komponen.getValue()) {
        var komponens = this.$refs.komponen.getValue().split(".");
        console.log(komponens);

        if (this.dataVersi.programs) {
          this.dataVersi.programs.forEach((v) => {
            if (v.kdprogram == komponens[5]) {
              v.giats.forEach((b) => {
                if (b.kdgiat == komponens[6]) {
                  b.outputs.forEach((c) => {
                    if (c.kdoutput == komponens[7]) {
                      c.saktiDSoutputs.forEach((d) => {
                        if (d.kdsoutput == komponens[8]) {
                          d.saktiDKmpnens.forEach((e) => {
                            if (e.kdkmpnen == komponens[9]) {
                              e.saktiDSkmpnens.forEach((f) => {
                                this.currentItem.listSKomponenCombo.push({
                                  text: f.kdskmpnen + " - " + f.urskmpnen,
                                  value:
                                    f.thang +
                                    "." +
                                    f.kdjendok +
                                    "." +
                                    f.kdsatker +
                                    "." +
                                    f.kddept +
                                    "." +
                                    f.kdunit +
                                    "." +
                                    f.kdprogram +
                                    "." +
                                    f.kdgiat +
                                    "." +
                                    f.kdoutput +
                                    "." +
                                    f.kdsoutput +
                                    "." +
                                    f.kdkmpnen +
                                    "." +
                                    f.kdskmpnen +
                                    "." +
                                    f.kdlokasi +
                                    "." +
                                    f.kdkabkota +
                                    "." +
                                    f.kddekon,
                                });
                              });
                            }
                          });
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        }
      }

      this.$refs.skomponen.load(false);
    },
    toggleSub(key) {
      this.isActive[key] = !this.isActive[key];
      if (this.isActive[key]) {
        $("#sub_" + key).hide();
      } else {
        $("#sub_" + key).show();
      }
    },
    cariData() {
      if (!this.$refs.versi.getValue()) {
        this.$refs.notif.info(`Pilih versi yang akan ditampilkan`);
        return;
      }

      urutan = 0;
      let myForm = document.getElementById("formCari");
      let formData = new FormData(myForm);

      console.log(filter);

      // filter = `&kdsatker=030156&hasProyekOnly=true`;

      if (this.$refs.form.validate()) {
        this.$refs.btnAct.load(true);
        this.G_GetAny(
          "rkakl/data?saktiVersionId=" + this.$refs.versi.getValue()
        ).then((res) => {
          console.log(res.data);
          this.dataVersi = res.data;

          if (this.dataVersi.programs) {
            this.dataVersi.programs.forEach((v) => {
              console.log();
              this.currentItem.listProgramCombo.push({
                text:
                  v.kddept +
                  "." +
                  v.kdunit +
                  "." +
                  v.kdprogram +
                  " - " +
                  v.nmprogram,
                value:
                  v.thang +
                  "." +
                  v.kdjendok +
                  "." +
                  v.kdsatker +
                  "." +
                  v.kddept +
                  "." +
                  v.kdunit +
                  "." +
                  v.kdprogram,
              });
            });
          }

          $("#tableShow").fadeIn();
          this.$refs.btnAct.load(false);
          this.loadingTb = false;
        });
      }
    },
    setNoUrut(idx) {
      // urutan++
      // return urutan
    },
    setSatker(id) {
      if (id != "all" || id == null) {
        this.G_GetRef("satker", "?balaiId=" + id).then((data) => {
          this.listStatker = data;
          // this.listStatker.unshift({
          //   text: "Semua Satker",
          //   value: "all",
          // });
        });
      } else {
        this.G_GetRef("satker").then((data) => {
          this.listStatker = data;
          // this.listStatker.unshift({
          //   text: "Semua Satker",
          //   value: "all",
          // });
        });
      }
    },
    tableCSV() {
      var workbook = XLSX.utils.book_new();

      var worksheet_data = document.getElementById("tableShow");
      var worksheet = XLSX.utils.table_to_sheet(worksheet_data);

      workbook.SheetNames.push("Test");
      workbook.Sheets["Test"] = worksheet;

      //  exportExcelFile(workbook);

      return XLSX.writeFile(
        workbook,
        "Progres Per Paket " + this.G_today + ".xlsx"
      );
      var data = ["waw"];
      var blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      var objectUrl = URL.createObjectURL(blob);
      window.open(objectUrl);
    },
    tableExcel() {
      var a = document.createElement("a");
      //getting data from our div that contains the HTML table
      var data_type = "data:application/vnd.ms-excel";
      var table_div = document.getElementById("tableShow");
      var table_html = table_div.outerHTML.replace(/ /g, "%20");
      a.href = data_type + ", " + table_html;
      //setting the file name
      a.download = "Progres Per Paket " + this.G_today + ".xls";
      //triggering the function
      a.click();
      //just in case, prevent default behaviour
      e.preventDefault();

      // window.open('data:application/vnd.ms-excel;charset=utf-8,' + encodeURIComponent( $('div[id$=tableShow]').html()));
      // e.preventDefault();
    },
    selectAll(type) {
      if (type == "kolom") {
        this.$refs.colomTable.selectAll();
      } else if (type == "giat") {
        this.$refs.giat.selectAll();
      }
    },
    removeAll(type) {
      if (type == "kolom") {
        this.$refs.colomTable.removeAll();
      } else if (type == "giat") {
        this.$refs.giat.removeAll();
      }
    },
    editSubKomponen(id, urskmpnen) {
      this.currentSubKomponen.id = id;
      this.currentSubKomponen.urskmpnen = urskmpnen;

      this.$refs.modalSubKomponen.open("Ubah Sub Komponnen", 600);
    },
    editSubOutput(id, vol, name) {
      console.log(id);
      this.currentSubOutput.id = id;
      this.currentSubOutput.beforeVol = vol;
      this.currentSubOutput.name = name;
      this.$refs.modalSubOutput.open("Ubah Volume Sub Output", 600);
    },
    addItem(id) {
      this.$refs.modalItem.open("Tambah Data Item", 1200);
    },
    async exportRkakl() {
      if (!this.$refs.versi.getValue()) {
        this.$refs.notif.info(`Pilih versi yang akan di export`);
        return;
      }

      if (this.$refs.form.validate()) {
        this.$refs.btnExport.load(true);
        this.G_GetAny(
          "rkakl/export?saktiVersionId=" + this.$refs.versi.getValue()
        ).then((res) => {
          // console.log(res.data);
          this.dataVersi = res.data;

          console.log(process.env.VUE_APP_URL_API + this.dataVersi.fileUrl);
          window.open(
            process.env.VUE_APP_URL_API + this.dataVersi.fileUrl,
            "_blank"
          );

          this.$refs.btnExport.load(false);
        });
      }
    },
    komentarList(paramCurrentSOutput) {
      this.G_GetAny(
        "sakti/diskusi?kdhistory=" + paramCurrentSOutput.kdHistory
      ).then((res) => {
        let resData = res.data.content;

        if (resData) {
          this.listKomentar = resData;
        }
        console.log(resData);
      });

      this.currentKomentar.kdHistory = paramCurrentSOutput.kdHistory;
      this.$refs.modalKomentar.open("Komentar", 800);
    },
    komentarEditList(komentar, index) {
      console.log(komentar.komentar);
      this.currentKomentarEdit.id = komentar.id;
      this.currentKomentarEdit.komentar = komentar.komentar;
      this.currentKomentarEdit.indexEdit = index;
      this.currentKomentarEdit.key++;
      this.$refs.modalKomentarEdit.open("Edit Komentar", 800);
    },
    storeSubKomponen() {
      let myForm = document.getElementById("formDataSubKomponen");
      let formData = new FormData(myForm);

      if (this.$refs.form.validate()) {
        formData.append("id", this.currentSubKomponen.id);

        this.$refs.modalSubKomponen.loading();

        this.G_PutAny("rkakl/sub-komponen/nama", formData)
          .then((res) => {
            console.log(res);
            if (!res.status) {
              this.cariData();
              this.$refs.notif.success(`Berhasil mengupdate Nama Sub Komponen`);
              this.$refs.modalSubKomponen.close();
            } else {
              this.$refs.notif.warning("Terjadi Kesalahan");
            }
          })
          .finally(() => {
            this.$refs.modalSubKomponen.loadingEnd();
          });
      }
    },
    storeSubOutput() {
      let myForm = document.getElementById("formDataSubOutput");
      let formData = new FormData(myForm);

      if (this.$refs.form.validate()) {
        formData.append("id", this.currentSubOutput.id);

        this.$refs.modalSubOutput.loading();

        this.G_PutAny("rkakl/sub-output/volume", formData)
          .then((res) => {
            console.log(res);
            if (!res.status) {
              this.cariData();
              this.$refs.notif.success(`Berhasil mengupdate Volume Sub Output`);
              this.$refs.modalSubOutput.close();
            } else {
              this.$refs.notif.warning("Terjadi Kesalahan");
            }
          })
          .finally(() => {
            this.$refs.modalSubOutput.loadingEnd();
          });
      }
    },

    storeKomentar() {
      let myForm = document.getElementById("formDataKomentar");
      let formData = new FormData(myForm);

      if (this.$refs.formDataKomentar.validate()) {
        formData.append("user_id", this.getUser.userId);
        formData.append("kdhistory", this.currentKomentar.kdHistory);

        this.$refs.modalKomentar.loading();

        this.G_PostAny("sakti/diskusi", formData)
          .then((res) => {
            if (!res.status) {
              this.currentKomentar.komentar = "";
              this.currentKomentar.key++;
              this.$refs.notif.success(`Berhasil menambahkan komentar`);
              // this.$refs.modalKomentar.close();
              res.name = this.getUser.name;
              this.listKomentar.unshift(res);
              this.$refs.timeline.scrollTop = 0;
              this.$refs.komentar = "";
            } else {
              this.$refs.notif.warning("Terjadi Kesalahan");
            }
          })
          .finally(() => {
            this.$refs.modalKomentar.loadingEnd();
          });
      } else {
        console.log("unvalidate");
      }
    },

    storeEditKomentar() {
      let myForm = document.getElementById("formDataEditKomentar");
      let formData = new FormData(myForm);

      if (this.$refs.formDataEditKomentar.validate()) {
        formData.append("id", this.currentKomentarEdit.id);

        this.$refs.modalKomentarEdit.loading();

        this.G_PutAny("sakti/diskusi", formData)
          .then((res) => {
            if (!res.status) {
              this.listKomentar[this.currentKomentarEdit.indexEdit].komentar =
                res.komentar;
              this.currentKomentarEdit.komentar = "";
              this.currentKomentarEdit.key++;
              this.currentKomentar.key++;
              this.$refs.notif.success(`Berhasil mengupdate komentar`);
              this.$refs.modalKomentarEdit.close();
            } else {
              this.$refs.notif.warning("Terjadi Kesalahan");
            }
          })
          .finally(() => {
            this.$refs.modalKomentarEdit.loadingEnd();
          });
      }
    },

    editAkun(item) {
      this.$refs.modalAkunEdit.open("Edit Data Akun", 1200);

      console.log(item.id);
      console.log(item.kdakun);
      console.log(item.register);
      console.log(item.kdbeban + item.kdjnsban + item.kdctarik);
      this.currentAkunEdit.id = item.id;
      this.currentAkunEdit.kdkppn = item.kdkppn;
      this.currentAkunEdit.kdakun = item.kdakun;
      this.currentAkunEdit.register = item.register;
      this.currentAkunEdit.kdbeban =
        item.kdbeban + item.kdjnsban + item.kdctarik;
    },

    editItem(item) {
      console.log(item);
      this.$refs.modalItemEdit.open("Edit Data Item", 1200);

      this.currentItemEdit = item;
    },
    storeItem() {
      let myForm = document.getElementById("formDataItem");
      let myFormData = new FormData(myForm);

      if (this.$refs.form.validate()) {
        var object = {};

        console.log("post");
        let kdskmpnen = this.$refs.skomponen.getValue().split(".");
        console.log(kdskmpnen);
        let formData = new FormData();
        formData.append("thang", kdskmpnen[0]);
        formData.append("kdjendok", kdskmpnen[1]);
        formData.append("kdsatker", kdskmpnen[2]);
        formData.append("kddept", kdskmpnen[3]);
        formData.append("kdunit", kdskmpnen[4]);
        formData.append("kdprogram", kdskmpnen[5]);
        formData.append("kdgiat", kdskmpnen[6]);
        formData.append("kdoutput", kdskmpnen[7]);
        formData.append("kdsoutput", kdskmpnen[8]);
        formData.append("kdkmpnen", kdskmpnen[9]);
        formData.append("kdskmpnen", kdskmpnen[10]);
        formData.append("kdlokasi", kdskmpnen[11]);
        formData.append("kdkabkota", kdskmpnen[12]);
        formData.append("kddekon", kdskmpnen[13]);

        formData.append("saktiVersionId", this.dataVersi.saktiVersionId);
        formData.append(
          "kodePaket",
          kdskmpnen[4] +
            "." +
            kdskmpnen[2] +
            "." +
            kdskmpnen[5] +
            "." +
            kdskmpnen[6] +
            "." +
            kdskmpnen[7] +
            "." +
            kdskmpnen[8] +
            "." +
            kdskmpnen[9] +
            "." +
            kdskmpnen[10]
        );

        formData.append("kdakun", this.$refs.akun.getValue());

        myFormData.forEach((value, key) => {
          console.log(key + " - " + value);
          if (key == "name") formData.append("nmitem", value);
          if (key == "vol1") formData.append("vol1", value);
          if (key == "sat1") formData.append("sat1", value);
          if (key == "vol2") formData.append("vol2", value);
          if (key == "sat2") formData.append("sat2", value);
          if (key == "vol3") formData.append("vol3", value);
          if (key == "sat3") formData.append("sat3", value);
          if (key == "vol4") formData.append("vol4", value);
          if (key == "sat4") formData.append("sat4", value);
          if (key == "volkeg") formData.append("volkeg", value);
          if (key == "satkeg") formData.append("satkeg", value);
          if (key == "register") formData.append("register", value);
          if (this.$refs.bebanitem.getValue()) {
            formData.append(
              "kdbeban",
              this.$refs.bebanitem.getValue().substring(0, 1)
            );
            formData.append(
              "kdjnsban",
              this.$refs.bebanitem.getValue().substring(1, 2)
            );
            formData.append(
              "kdctarik",
              this.$refs.bebanitem.getValue().substring(2, 3)
            );
          }

          if (key == "kdkppn") formData.append("kdkppn", value);
        });
        formData.append("hargasat", this.paramHargaSat);
        formData.append("jumlah2", 0);
        formData.append("paguphln", 0);
        formData.append("pagurmp", 0);
        formData.append("pagurkp", 0);
        formData.append("kdblokir", 0);
        formData.append("blokirphln", 0);
        formData.append("blokirrmp", 0);
        formData.append("blokirrkp", 0);
        formData.append("rphblokir", 0);
        formData.append("carahitung", 0);
        formData.append("kdsbu", " ");
        formData.append("volsbk", 0);
        formData.append("volrkakl", 0);
        formData.append("nokontrak", " ");
        formData.append("nilkontrak", 0);
        formData.append("jmltunda", 0);
        formData.append("jmlabt", 0);
        formData.append("kurs", 1);
        formData.append("indexkpjm", 0);
        formData.append("kdib", "00");

        this.$refs.modalItem.loading();

        this.G_PostAny("sakti-d-item", formData)
          .then((res) => {
            console.log(res);
            if (!res.status) {
              this.cariData();
              this.$refs.notif.success(`Berhasil menambahkan item`);
              this.$refs.modalItem.close();
            } else {
              this.$refs.notif.warning("Terjadi Kesalahan");
            }
          })
          .finally(() => {
            this.$refs.modalItem.loadingEnd();
          });
      }
    },

    storeEditItem() {
      let myForm = document.getElementById("formDataItemEdit");
      let formData = new FormData(myForm);

      if (this.$refs.form.validate()) {
        formData.append("id", this.currentItemEdit.id);
        formData.append("hargasat", this.currentItemEdit.hargasat);

        this.$refs.modalItemEdit.loading();

        this.G_PutAny("sakti-d-item", formData)
          .then((res) => {
            console.log(res);
            if (!res.status) {
              this.cariData();
              this.$refs.notif.success(`Berhasil mengupdate item`);
              this.$refs.modalItemEdit.close();
            } else {
              this.$refs.notif.warning("Terjadi Kesalahan");
            }
          })
          .finally(() => {
            this.$refs.modalItemEdit.loadingEnd();
          });
      }
    },

    storeEditAkun() {
      let myForm = document.getElementById("formDataAkunEdit");
      let formData = new FormData(myForm);

      if (this.$refs.form.validate()) {
        formData.append("id", this.currentAkunEdit.id);

        if (this.$refs.beban.getValue()) {
          formData.append(
            "kdbeban",
            this.$refs.beban.getValue().substring(0, 1)
          );
          formData.append(
            "kdjnsban",
            this.$refs.beban.getValue().substring(1, 2)
          );
          formData.append(
            "kdctarik",
            this.$refs.beban.getValue().substring(2, 3)
          );
        }

        this.$refs.modalAkunEdit.loading();

        this.G_PutAny("/sakti-d-akun", formData)
          .then((res) => {
            console.log(res);
            if (!res.status) {
              this.cariData();
              this.$refs.notif.success(`Berhasil mengupdate akun`);
              this.$refs.modalAkunEdit.close();
            } else {
              this.$refs.notif.warning("Terjadi Kesalahan");
            }
          })
          .finally(() => {
            this.$refs.modalAkunEdit.loadingEnd();
          });
      }
    },

    async deleteItem(item) {
      let judul = "Hapus Item";
      let subJudul = "Apakah anda akan menghapus item ini?";

      if (await this.$refs.confirm.open(judul, subJudul)) {
        this.$refs.confirm.loading();

        let formData = new FormData();
        this.G_PutAny("sakti-d-item/" + item.id, formData)
          .then((res) => {
            console.log(res);
            if (!res.status) {
              this.$refs.confirm.close();
              this.cariData();
              this.$refs.notif.success(`Berhasil menghapus item`);
            } else {
              this.$refs.notif.warning("Terjadi Kesalahan");
            }
          })
          .finally(() => {
            this.$refs.confirm.loadingEnd();
          });
      }
    },

    calculateJumlah() {
      let myForm = document.getElementById("formDataItem");
      let formData = new FormData(myForm);
      let volkeg = 0;

      formData.forEach((value, key) => {
        if (key == "volkeg") volkeg = value;
      });

      if (volkeg && this.paramHargaSat) {
        this.paramJumlah = volkeg * this.paramHargaSat;
      }
    },

    calculateJumlahEdit() {
      let myForm = document.getElementById("formDataItemEdit");
      let formData = new FormData(myForm);
      let volkeg = 0;

      formData.forEach((value, key) => {
        if (key == "volkeg") volkeg = value;
      });

      if (volkeg && this.currentItemEdit.hargasat) {
        this.currentItemEdit.jumlah = volkeg * this.currentItemEdit.hargasat;
      }
    },
  },
};
